

.tab-nav-container {
    background: var(--secondary-color);
    padding: .5rem;
    border-radius: 50px;
}

.tab-nav-container .tab {
    padding: 1rem;
    background: #fff;
    margin: 1rem 0.5rem;
    border-radius: 30px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.tab-nav-container .active-tab {
    background: var(--primary-color);
    color: #fff;
}

.my-table {
    width: 90%;
border-collapse: collapse;
margin-top: 20px; /* Add margin to the top of the table */
/* Add other styles as needed */
}

