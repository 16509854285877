@import url("https://fonts.googleapis.com/css2family=Roboto:wght@400;500;700;900&display=swap&family=Inter:wght@300;400;500;600;700;800;900&");

:root {
  --primary-color: #f53e61;
  --secondary-color: #f6f2f4;
}

* {
  font-family: "Inter", sans-serif;
  color: #444;
}

.App {
  /* background: #232424; */
  /* min-height: 100vh; */
  overflow-x: hidden;
}

.beta {
  background: #ffa629;
  color: #65372a;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 0.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

.bg-primary {
  background: var(--primary-color);
}

.bg-secondary {
  background: var(--secondary-color);
}

.page-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.page-header h1 {
  font-size: 1.7rem;
  font-weight: 600;
  max-width: 24rem;
  text-align: center;
}

.page-header .logo img {
  height: 4rem;
}

.page-header .account img {
  height: 2rem;
}

.home-container {
  display: grid;
  grid-template-columns: 6% 94%;
  grid-template-areas: "side-nav main-view";
}

.main-view {
  grid-area: main-view;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 4rem 0;
}

.form-title {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 2rem;
  max-width: 24rem;
  text-align: center;
}

.bookish-input-group .bookish-input-label {
  color: #444444;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.9rem;
}

.bookish-input-group .bookish-input-label .required {
  color: var(--primary-color);
  font-weight: 600;
}

.bookish-input-group .bookish-input-field {
  background: var(--secondary-color);
  padding: 0.7rem 1.5rem;
  border-radius: 20px;
  width: 23rem;
  outline: none;
  border: 2px solid var(--secondary-color);
}

.bookish-input-group .bookish-input-multi-label {
  background: var(--secondary-color);
  padding: 0.7rem 1.5rem;
  border-radius: 20px;
  width: 23rem;
  outline: none;
}

.bookish-input-group .bookish-input-multi-label .card {
  font-size: 1rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 3rem;
  margin: 0.2rem;
}

.bookish-input-group .bookish-input-multi-label .left {
  font-size: 1.6rem;
}

.bookish-input-group .bookish-input-multi-label .left .small {
  font-size: 1rem;
}

.bookish-input-group .bookish-input-multi-label .right {
  font-size: 1.5rem;
  background: #fff;
  padding: 0.5rem;
  border-radius: 3rem;
}

.bookish-input-group .bookish-dropdown-field {
  background: var(--secondary-color);
  padding: 0.7rem 1.5rem;
  border-radius: 15px;
  width: 23rem;
  outline: none;
}

.bookish-input-group .bookish-primary-btn[disabled] {
  opacity: 0.7;
  font-size: 1rem;
  padding: 0.65rem;
}

.boost-btn {
  background: var(--primary-color);
  color: #fff;
  font-weight: 600;
  border-radius: 1rem;
  cursor: pointer;
}

.boost-btn span {
  color: #fff;
  font-weight: 600;
}

.boost-btn-secondary {
  background: #fff;
  color: var(--primary-color);
  font-weight: 700;
  border: 2px solid var(--primary-color);
  border-radius: 1rem;
  cursor: pointer;
}

.boost-btn-secondary span {
  color: var(--primary-color);
  font-weight: 700;
}

.bookish-input-group .tag-view {
  background: #f6f6f6;
  padding: 0.3rem 1rem;
  margin: 0.3rem 0.4rem;
  border-radius: 20px;
  font-size: 0.85rem;
  line-height: 1.1;
}

.bookish-input-group .sample-prompt {
  background: #f6f6f6;
  padding: 0.3rem 1rem;
  margin: 0.3rem 0;
  border-radius: 20px;
  font-size: 0.85rem;
  line-height: 1.1;
}



.chat-container .sample-prompt .tier-patron,
.chat-container .sample-prompt .tier-premium {
  padding: 6px;
  border-radius: 16px;
  font-size: 12.5px;
  font-weight: 600;
  width: 4.7rem !important;
  text-align: center;
  display: inline-block; /* Ensures the width is respected */
}

.chat-container .sample-prompt .tier-patron {
  background: #89cff0;
  color: #444;
}

.chat-container .sample-prompt .tier-premium {
  background: #ffa629;
  color: #65372a;
  padding: 0.2rem 0.5rem;
  font-weight: 600;
  border-radius: 0.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  /* text-transform: uppercase; */
}


.bookish-input-group .bookish-primary-btn {
  background: var(--primary-color);
  color: #fff;
  padding: 0.5rem;
  border-radius: 25px;
  width: 23rem;
  outline: none;
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.bookish-input-group .bookish-cancel-btn {
  background: var(--secondary-color);
  color: #444;
  padding: 0.5rem;
  border-radius: 25px;
  width: 18rem;
  outline: none;
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 400;
}

.bookish-input-group .bookish-normal-btn {
  background: var(--secondary-color);
  color: #444;
  padding: 0.5rem;
  border-radius: 10px;
  outline: none;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
}

.bookish-input-group .bookish-select-box {
  background: var(--secondary-color);
  padding: 0.7rem 1.5rem;
  border-radius: 10px;
  width: 23rem;
  outline: none;
  border: 2px solid var(--secondary-color);
}

.bookish-input-group .bookish-on-off {
  background: var(--secondary-color);
  padding: 0.7rem 1.5rem;
  border-radius: 10px;
  width: 23rem;
  outline: none;
  border: 2px solid var(--secondary-color);
}

.bookish-input-group .bookish-on-off .rectangle {
  width: 57px;
  height: 32px;
  background: #b9d7e8;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
}

.bookish-input-group .bookish-on-off .rectangle .ellipse {
  padding: 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #fff;
  margin: 6px 5px;

  border-radius: 50%;
  background: #fff;
  transition-duration: 0.2s;
}

.bookish-input-group .bookish-on-off .rectangle-active {
  background: #f53e61;
}

.bookish-input-group .bookish-on-off .rectangle .ellipse-active {
  padding: 10px;
  height: 10px;
  width: 10px;
  margin: 6px 31px;
}

.bookish-input-group .bookish-character-textarea {
  width: 40rem;
}

.bookish-input-group .files .file {
  border: 2px solid var(--secondary-color);
  padding: 0.5rem 1.5rem;
  padding-right: 8px;
  border-radius: 10px;
  width: 23rem;
  outline: none;
}

.bookish-input-group .files .file .file-icon {
  height: 1.5rem;
}

input:focus,
textarea:focus {
  border: 2px solid #6366f1 !important;
  outline: --primary-color;
}

th,
td {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 40px;
}

.table-status {
  background: #d7f7c2;
  color: rgb(0, 105, 8);
  font-weight: 600;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.5rem;
}

.bookish-input-group .bookish-primary-btn > {
  font-size: 1.5rem;
  font-weight: 500;
}

.bookish-input-group .bookish-secondary-btn {
  background: #fff;
  color: #444444;
  padding: 0.5rem;
  border-radius: 25px;
  width: 23rem;
  outline: none;
  font-size: 1.1rem;
  font-weight: 500;
}

.bookish-file-upload {
  background: var(--secondary-color);
  border-radius: 20px;
  width: 23rem;
}

.bookish-file-upload .round-person {
  border-radius: 15px;
  height: 5rem;
}

.bookish-file-upload .circle-person {
  border-radius: 365px;
  height: 5rem;
}

.bookish-file-upload p {
  text-align: center;
  cursor: pointer;
}

.header {
  width: 100%;
}

.header h1 {
  font-size: 2rem;
  font-weight: 600;
}

.header .account {
  margin-left: auto;
}

.header .account img {
  height: 2rem;
  margin-top: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 6%; /* To match the 10% width you mentioned */
  background: #f6f2f4;
  padding: 4.5rem 0;
  overflow-y: auto; /* Add a vertical scrollbar when content overflows */
}

.main-nav {
  width: 100%;
}

.nav-btn {
  margin: 0 auto;
  margin-top: 0.4rem;
  padding: 1rem;
  width: 4.5rem;
}

.nav-btn:hover {
  background: #dad5d5;
  border-radius: 12px;
}

.nav-active {
  background: #dad5d5;
  border-radius: 12px;
}

.nav-btn p {
  padding-top: 0.5rem;
}

.nav-btn img {
  width: 1.5rem;
  margin-left: 0.6rem;
}

.active {
  background: #e4e5df;
  border-radius: 12px;
  width: 95%;
}

/* h1 {
  padding: 0 0 .8rem .8rem;
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
} */

.min-title {
  font-size: 1rem;
  font-weight: 600;
}

.character-card {
  background: #ebebeb;
  border-radius: 15px;
  padding: 1.5rem;
  margin: 0.8rem;
  margin-left: 0;
  cursor: pointer;
  width: 14rem;
}

.character-card:hover {
  background: #dad5d5;
}

.character-card .character-name {
  text-align: center;
  margin-top: 0.8rem;
  font-weight: 700;
  color: var(--primary-color);
}

.character-card .character-desc {
  width: 90%;
  text-align: center;
  margin-top: 0.8rem;
  margin: auto;
  font-weight: 400;
  font-size: 0.7rem;
}

.character-card img {
  height: 5rem;
  border-radius: 365px;
  margin: auto;
}

.tags .tag {
  background: #f6f2f4;
  border-radius: 13px;
  padding: 0.65rem 1.4rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  margin: 1rem 0;
  width: auto;
  white-space: nowrap;
}

.tags .active {
  background: var(--primary-color);
  color: #fff;
}

.saying-container .saying {
  width: 20rem;
  margin: 1rem 0;
}

.saying-container .saying img {
  height: 50px;
  border-radius: 6px;
}

.saying-container
  .saying
  .Mr
  Robot
  Hello
  Mr
  Robot
  Hello
  theresaying-character-name {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 1rem;
}

.saying-container .saying .saying-italic {
  font-style: italic;
}

.saying-container .saying .sample-message {
  background: #ebebeb;
  border-radius: 10px;
  padding: 0.5rem;
  margin-top: 0.8rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.saying-container .saying .sample-message:hover {
  opacity: 0.9;
}

.back {
  width: 70%;
  margin: auto;
  padding-top: 0.6rem;
}

.back img {
  height: 3rem;
}

.back .page-header-title {
  color: #c8c4bd;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.favourites {
  width: 60%;
  margin: auto;
}

.favourites .favourites-title {
  margin: 2rem auto 1rem auto;
  font-size: 1.6rem;
}

.favourites .favourite-card {
  background: var(--secondary-color);
  padding: 1rem;
  border-radius: 15px;
  margin-top: 1rem;
  cursor: pointer;
}

.favourites .favourite-card:hover {
  opacity: 0.9;
}

.favourites .favourite-title {
  font-weight: 600;
  color: #444;
  font-size: 1.1rem;
}

.favourites .favourite-character {
  font-style: italic;
  color: #444;
}

.favourites .favourite-italic {
  font-style: italic;
  color: #444;
}

.favourites .character-img {
  height: 60px;
  border-radius: 10px;
}

.billing {
  width: 60%;
  margin: auto;
  margin-top: 2rem;
}

.billing .billing-card {
  background: #3d3e3e;
  padding: 1rem;
  border-radius: 15px;
  margin-top: 1rem;
}

.billing .billing-title {
  color: #c8c4bd;
  font-size: 1.2rem;
  font-weight: 600;
}

.billing .billing-secondary-title {
  color: #c8c4bd;
  font-size: 1rem;
  font-weight: 700;
}

.billing .billing-third-title {
  color: #c8c4bd;
  font-size: 0.8rem;
  font-weight: 700;
}

.billing .usage-slide {
  background: #d9d9d9;
  border-radius: 17px;
}

.billing .usage-amount {
  background: #4f46e5;
  border-radius: 17px;
  border: solid 1px #4f46e5;
  width: 30%;
  padding: 0.7rem;
}

.billing .usage-price {
  color: #c8c4bd;
  font-weight: 600;
}

.billing .token-usage {
  color: #c8c4bd;
  font-weight: 600;
  font-size: 0.8rem;
}

.billing .billing-rate-limit-info {
  color: #c8c4bd;
  font-size: 0.7rem;
  margin-top: 0.3rem;
}

.billing input {
  background: #c8c4bd;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 0.71rem;
  color: #3d3e3e;
  font-weight: 600;
  border: none;
}

.billing .rate {
  color: #3d3e3e;
  background: #c8c4bd;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0.91rem;
  border: none;
}

.billing button {
  background: #d9d9d9;
  border-radius: 17px;
  color: #3d3e3e;
  font-weight: 700;
  padding: 0.7rem 1.2rem;
}

.chat-container {
  display: flex;
}

.chat-page-header {
  display: none;
}

.chat-container .chat-side-nav {
  width: 20%;
  height: 100vh;
  max-height: auto;
  background: #f6f2f4;
  padding: 1rem 0;
}

.chat-container .chat-main {
  width: 80%;
}

.chat-container .chat-side-nav .chat-menu {
  width: 90%;
  background: #ebebeb;
  margin: auto;
  padding: 1rem 0.5rem;
  border-radius: 5px;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
}

.chat-container .chat-side-nav .chat-menu div {
  cursor: pointer;
}

.chat-container .chat-side-nav .chat-menu img {
  height: 1.5rem;
}

.chat-container .chat-side-nav .chat-menu p {
  margin-top: 10px;
}

.chat-container .chat-side-nav .sample-prompt {
  background: #ebebeb;
  padding: 0.7rem;
  border-radius: 5px;
  width: 90%;
  margin: 0.2rem auto;
  font-size: 0.78rem;
  line-height: 1.2;
  font-weight: 400;
  cursor: pointer;
}

.chat-container .chat-side-nav .sample-prompt:hover {
  background: #e2e0e0;
}

.chat-container .chat-side-nav .jump {
  width: 90%;
  padding: 0.5rem 0;
  margin: auto;
  font-weight: 500;
}

.chat-container .input-box-container {
  position: fixed;
  bottom: 0;
  padding: 1rem 0;
  width: 80%;
  background-color: #fff;
}

.chat-container .suggestion-prompts .suggestion-prompt {
  padding: 0.7rem;
  border: 2px solid #e1e2e7;
  border-radius: 0.5rem;
  margin-right: auto;
  min-width: 100%;
  max-width: 40rem;
}

.chat-container .input-box-container .the-field-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px; /* Stick to the bottom */
  width: 95%; /* Set the width */
  left: 50%; /* Move the container to the center */
  transform: translateX(-50%); /* Adjust to center based on width */
}


.chat-container .input-box-container .suggestion-prompts {
  overflow-y: scroll;
  max-height: 700px;
}

.chat-container .input-box {
  background: #e1e2e7;
  border-radius: 10px;
  width: 44rem;
}

.chat-container .input-box input {
  background: #e1e2e7;
  font-weight: 500;
  padding: 0.75rem;
  outline: none;
  width: 100%;
}

.chat-container .input-box span {
  background: #e1e2e7;
  color: #c8c4bd;
  border-color: #e1e2e7;
}

.chat-container .input-box .send {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chat-container .input-box .add {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0.4rem;
}

.chat .conversation-container {
  height: 70vh;
  overflow: auto;
}

.chat .chat-message .person-photo {
  padding: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 365px;
  background: #3d3e3e;
}

.chat .chat-message .character-photo {
  height: 3rem;
  width: auto;
  border-radius: 365px;
}

.chat .chat-message .chat-account {
  font-weight: 600;
  margin-left: auto;
  color: #444;
  font-size: 0.8rem;
}

.chat .chat-message .chat-view-text-response {
  background: var(--primary-color);
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  padding: 0.2rem 0.2rem;
  border-radius: 5px;
  margin-left: 0.5rem;
}

.chat .chat-message .chat-box {
  border-radius: 0.375rem;
  padding: 0.6rem;
  border-radius: 10px;
  width: 44rem;
  font-size: 0.875rem;
}

.chat .logo {
  height: 5rem;
  display: flex;
  justify-content: center;
}

.chat .character-name {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.chat .character-desc {
  font-size: 1rem;
  text-align: center;
}

.chat .width-of-chat {
  width: 90%;
  margin: auto;
}

.chat .assistant {
  background: var(--primary-color);
  color: #fff;
}

.chat .user {
  background: var(--secondary-color);
  color: #444;
}

.admin .admin-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.admin .admin-header-active {
  border-bottom: 3px solid var(--primary-color);
  background: #fff;
  border-radius: 0px;
  margin-top: 0.7rem;
}

.admin .admin-header h1 {
  font-size: 1.7rem;
  font-weight: 600;
  max-width: 24rem;
  text-align: center;
}

.admin .logo img {
  height: 5rem;
}

.admin .edit-character {
  width: 95%;
  margin: 2.5rem 0;
  margin-left: auto;
  overflow-x: auto;
}

.admin .title {
  font-size: 1.5rem;
}

.admin .sub-title {
  font-size: 1rem;
}

.admin .edit-character .character-card {
  background: #ebebeb;
  border-radius: 0.9rem;
  width: 15rem;
}

.admin .edit-character .character-card .edit-character-title {
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
  margin-top: 0.5rem;
}

.admin .edit-character .character-card .edit-character-desc {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
}

.admin .edit-character .character-card .character-image {
  border-radius: 365px;
  height: 5rem;
}

.admin .edit-character .character-card .edit-icon {
  height: 2rem;
  margin-top: 0.5rem;
}

.admin .character-analytics-dashboard {
  width: 90%;
  margin: auto;
  overflow-x: scroll;
}

.admin .character-form {
  width: 80%;
}

.admin .character-form .create-character-mobile-btn {
  display: none;
}

.mobile-navigation {
  display: none;
}

.mobile-menu {
  position: fixed;
  background-color: #f1f2ed;
  min-height: 100%;
  max-height: auto;
  width: 0;
  top: 0;
  left: 0;
  z-index: 99;
  transition: width 0.3s ease;
  width: 100vw;
}

.mobile-menu h1 {
  color: #36434d;
}

.mobile-menu .mobile-navigation img {
  height: 4rem;
}

.mobile-menu .user-info-bottom {
  border-top: 2px solid #b0b2a8;
  padding-top: 1rem;
}

.mobile-menu .user-first-letter-icon {
  background: #d8d9d4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-sizing: border-box;
}

.mobile-menu .mobile-menu-list {
  height: 59vh !important;
  overflow-y: auto;
}

.mobile-menu .mobile-menu-list p {
  color: #36434d;
  font-size: 1.5rem;
}

.mobile-menu .email-address {
  font-size: 18px !important;
  font-weight: 500;
}

.mobile-menu .mobile-menu-list img {
  height: 2rem;
  fill: #36434d;
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .mobile-menu .mobile-menu-list {
    height: 50vh !important;
    overflow-y: auto;
  }
}

/* Styles for phones (typically smaller screens) */
@media only screen and (max-width: 767px) {
  /* Your CSS styles for phones go here */
  .home-container {
    display: block;
  }

  /* For User Subscriptions page */
  th,
  td {
    padding-left: 0;
  }

  .container {
    width: 92%;
    margin: 4rem auto;
  }

  .bookish-input-group .bookish-input-field {
    width: 20rem;
    margin: auto;
  }

  .bookish-input-group .bookish-input-multi-label {
    width: 20rem;
  }

  .bookish-input-group .bookish-primary-btn {
    width: 20rem;
  }

  .bookish-input-group .bookish-secondary-btn {
    width: 20rem;
  }

  .bookish-file-upload {
    width: 20rem;
  }

  .page-header h1 {
    font-size: 1.5rem;
  }

  .page-header .account {
    display: none;
  }

  .mobile-navigation {
    display: block;
  }

  .back {
    width: 95%;
  }

  .header h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .billing {
    width: 95%;
  }

  .favourites {
    width: 90%;
  }

  .favourites .character-img {
    height: 60px;
  }

  .favourites .favourite-title {
    font-size: 1.1rem;
  }

  .favourites .favourite-italic {
    font-size: 0.8rem;
  }

  .chat .width-of-chat {
    width: 95%;
  }

  .main-view {
    margin-left: 1rem;
  }

  .side-nav {
    display: none;
  }

  .chat-page-header {
    display: block;
  }

  .chat-container .chat-side-nav {
    display: none;
  }

  .chat-container .chat-main {
    width: 100%;
    margin: auto;
  }

  .chat .character-desc {
    font-size: 0.75rem;
  }

  .chat .chat-message .chat-box {
    border-radius: 0.375rem;
    padding: 0.6rem;
    border-radius: 10px;
    width: 15rem;
    font-size: 0.8rem;
  }

  .chat .conversation-container {
    height: 70vh;
    overflow: auto;
  }

  .chat-container .input-box-container {
    width: 100%;
    background: var(--secondary-color);
  }

  .chat-container .input-box-container > div {
    width: 95%;
    margin: auto;
  }

  .chat-container .suggestion-prompts .suggestion-prompt {
    border: 2px solid #fff;
  }

  .admin .character-form {
    display: block;
    width: 100%;
  }

  .admin .character-form .create-character-mobile-btn {
    display: block;
  }

  /* .admin .character-form .create-character-btn {
    display: none;
  } */
}

/* Styles for iPads (larger screens, in portrait orientation) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Your CSS styles for iPads go here */
  .billing {
    width: 80%;
  }

  .side-nav {
    display: none;
  }

  .chat-page-header {
    display: block;
  }

  .chat-container .chat-side-nav {
    display: none;
  }

  .chat-container .chat-main {
    margin: auto;
  }
}

/* Styles for larger screens, such as iPads (landscape orientation) */
@media only screen and (min-width: 1024px) {
  .chat .conversation-container {
    height: 80vh;
    overflow: auto;
  }
}

/* Style for the Y scroll bar */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  border-radius: 5px;
  background-color: var(--secondary-color);
}

/* Style for the Y scroll thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
}

/* Style for the Y scroll track */
::-webkit-scrollbar-track {
  background-color: var(--secondary-color);
  border-radius: 5px;
}

/* Add this CSS class to your project's CSS or import it into your component */
.Toastify__toast {
  background: #f6f2f4 !important; /* Set the background color to #F6F2F4 */
  /* Add any other styling properties you want for the toast component */
}

/* Add this CSS class to your project's CSS or import it into your component */
.Toastify__progress-bar {
  background-color: var(
    --primary-color
  ) !important; /* Set the color to the primary color variable */
  /* You can add other styling properties as needed */
}

.Toastify__toast-icon > svg {
  display: none !important;
}

.Toastify--animate-icon {
  display: none !important;
}
