
.assistants-edit {
    background: var(--secondary-color);
    width: 60rem;
    border-radius: .5rem;
    padding-bottom: 2rem;
}

.assistants-edit .tabs {
    padding: 1rem;
    border-radius: .5rem;
}

.assistants-edit .tab {
    background: #fff;
    padding: 1rem;
    margin-left: 1rem;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    border-radius: .5rem;

}

.assistants-edit .tab.active-tab {
    background: var(--primary-color);
    color: #fff;
}


.assistants-edit .tab-content {
    background: #fff;
    margin:  0 2rem 0 2rem;

    padding: 2rem;
    border-radius: .3rem;
}