.list-disc {
    list-style-type: none; /* Remove default list style */
    padding-left: 0; /* Optional: Adjust as needed */
  }
  
  .list-disc li {
    position: relative; 
    padding-left: 20px; /* Adjust based on the size of your custom marker */
  }
  
  .list-disc li::before {
    content: "•"; /* Unicode character for a bullet */
    color: var(--primary-color); /* Custom color */
    font-size: 25px; /* Custom size */
    position: absolute;
    left: 0; /* Align the bullet to the left */
    top: 0; /* Adjust this based on the line-height to center it */
  }
  